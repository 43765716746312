import React from 'react';
import styled from 'styled-components';
import Layout from '../components/Layout';

const NotFound = styled.h1`
  color: pink;
  font-size: 10vh;
  font-family: sans-serif;
`;

const TextSpan = styled.span`
  background-color: black;
`;

const PageNotFound = () => (
  <Layout>
    <NotFound>
      <TextSpan>
        Hey, <br /> you've <br />
        lost
        <br /> the route.
      </TextSpan>
    </NotFound>
  </Layout>
);

export default PageNotFound;
